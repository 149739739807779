export class Products {
  name: string;
  link: string;
  title: string;
  text: string;
  globalName?: string;
  textId?: string;
  menuCol?: any[];
  menuDescription?: string[];
  catalog: Nsb[];
  menuColumns?: string[];
  translate?: any;
  description?: string;
  order: number;
}
export class Set {
  name: string;
  article: string;
  title: string;
  img?: string;
  blockList?: boolean;
  blockDetails?: boolean;
  tableHide?: boolean;
  type?: number;
}
export class Nsb {
  name: string;
  blocked?: string[];
  id?: string;
  type?: string;
  noLinks?: boolean;
  material?: string;
  photo?: string[];
  photoContent?: string[];
  photoname?: string[];
  photoWidth?: any[];
  tehData?: string;
  replace?: any;
  selectedImage?: number;
  subText?: any;
  weight?: number;
  link?: string;
  size?: string[];
  sets?: any; //Set[];
  setsTitle?: string[];
  specifications?: any;
  overview?: any;
  specificationsGroup?: number[];
  html?: string;
  inform?: any;
  modules?: any;
  afterModules?: any;
  description?: any; //string;
  title?: any; //string;
  pdf?: any;
  titleText?: any;
  subtext?: any;
  points?: any; //string[];
  col?: number;
  data?: string[];
}
export class Exhibition {
  name: string;
  blocked?: string[];
  place: any;
  dateStart: string;
  dateEnd: string;
  booth: string;
  hall: string;
  photos: string[];
  dir?: string;
  banner?: string;
}
export class Exhibitions {
  year: number;
  list: Exhibition[];
}
