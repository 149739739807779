import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, Input, ViewEncapsulation} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { HostListener} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as json from '../../../assets/mock/products.json';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainNavComponent implements OnInit, OnDestroy {
  public openMenu = false;
  public openMenuEvents = false;
  public openMenuLanguage = false;
  public isVisible = false;
  public isVisibleEvents = false;
  public isVisibleLanguage = false;
  public openMobileMenu = false;
  public stickMenu = false;
  public products = [];
  public selectedProduct: any = {};
  public menuHide = [];
  public showProd = false;
  public showLang = false;
  public showFeedback = false;
  public calcWhite = false;
  public subs;
  public env = environment;
  public routerUrl = false;
  public lastCallProduct = 1;
  public openFromBottom = false;
  withoutQueryParams$: Observable<any>;
  @Input() set callProducts(callProduct: any) {
    if (this.lastCallProduct !== callProduct) {
      this.lastCallProduct++;
      if ( window.innerWidth > 850) {
        setTimeout(() => this.openMenuFunc(), 1);
        this.openFromBottom = true;
        this.changeDetector.markForCheck();
        setTimeout(() => this.openFromBottom = false, 260);
      } else {
        this.showFilterFunc();
        this.showProd = true;
      }
    }
  }
  @HostListener('window:scroll')
  onWindowScroll() {
    const scrollPos = ((document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop) > 10 ? true : false;
    if (scrollPos !== this.stickMenu) {
      this.stickMenu = scrollPos;
    }
  }

  constructor(private breakpointObserver: BreakpointObserver,
              private router: Router,
              private renderer: Renderer2,
              private changeDetector: ChangeDetectorRef,
              private route: ActivatedRoute) {
    const _this = this;
    this.subs = router.events.subscribe((val) => {
      const url = window.location.href;
      if (url) {
        const homVal = url.indexOf('home') !== -1 || url.indexOf('home2') !== -1;
        if (_this.routerUrl !== homVal) {
          _this.routerUrl = url.indexOf('home') !== -1 || url.indexOf('home2') !== -1;
          this.changeDetector.detectChanges();
        }
        const value = url.indexOf('/sitemap') !== -1 ||
          url.indexOf('/switch;id=') !== -1 ||
          url.indexOf('/enclosure;id=') !== -1 ||
          url.indexOf('/nsbox;id=') !== -1 ||
          url.indexOf('/surveillance;id=') !== -1 ||
          url.indexOf('/poe-injector;id=') !== -1 ||
          url.indexOf('/nsbon;id=') !== -1;
        if (value !== _this.calcWhite) {
          _this.calcWhite = value;
          _this.changeDetector.markForCheck();
        }
      }
    });
  }
  showFilterFunc() {
    this.showProd = false;
    this.menuHide = [];
    if (!this.openMobileMenu) {
      this.renderer.addClass(document.body, 'modal-open');
      this.openMobileMenu = true;
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
      this.openMobileMenu = false;
      this.showFeedback = false;
    }
  }

  openMenuFunc() {
    this.openMenuEvents = false;
    this.isVisibleEvents = false;
    this.openMenuLanguage = false;
    this.isVisibleLanguage = false;
    this.openMenu = true;
    this.isVisible = true;
    this.selectedProduct = this.products[2];
    this.changeDetector.markForCheck();
  }
  closeMenuFunc() {
    this.openMenu = false;
    if (!this.openFromBottom) {
      setTimeout(() => this.isVisible = false, 250);
    }
  }

  openMenuFuncEvents() {
    this.openMenu = false;
    this.isVisible = false;
    this.openMenuEvents = true;
    this.isVisibleEvents = true;
    this.openMenuLanguage = false;
    this.isVisibleLanguage = false;
  }

  openMenuFuncLanguage() {
    this.openMenu = false;
    this.isVisible = false;
    this.openMenuEvents = false;
    this.isVisibleEvents = false;

    this.openMenuLanguage = true;
    this.isVisibleLanguage = true;
  }

  closeMenuFuncEvents() {
    this.openMenuEvents = false;
    if (!this.openFromBottom) {
      setTimeout(() => this.isVisibleEvents = false, 250);
    }
  }

  navigateToFoo(lang) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        lg: lang === 'en' ? null : lang
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
  }

  closeMenuFuncLanguage(lang?) {
    this.openMenuLanguage = false;
    if (lang) {
      if (lang !== this.env.lang) {
        this.navigateToFoo(lang);
      }
    }
    setTimeout(() => this.isVisibleLanguage = false, 250);
  }

  ngOnInit(): void {
    // для [routerLinkActiveOptions]="{ exact: withoutQueryParams$ | async }
    // чтобы активная ссылка оставалась, даже если добавится в строке язык
    this.withoutQueryParams$ = this.route.queryParams.pipe(
      map(params => Object.keys(params).length === 0)
    );
    // удаляем элементы, которые должны быть заблокироваты к показу. например "ae"
    const host = this.env.getHost();
    this.products = JSON.parse(JSON.stringify(json.products));
    this.products.forEach(obj => obj.catalog = obj.catalog.filter(item => !(item.blocked && item.blocked.indexOf(host) > -1)));
    // конец
    this.selectedProduct = this.products[0];
    this.route.queryParams.subscribe( queryParams => {
      if (queryParams.lg && this.env.allLang.indexOf(queryParams.lg) <= -1) {
        this.navigateToFoo('en');
      } else {
        this.env.lang = queryParams.lg ? queryParams.lg : 'en';
        this.env.queryParams = this.env.lang === 'en' ? null : this.env.lang;
        this.changeDetector.markForCheck();
      }
    });
  }

  hoverProduct(product) {
    this.selectedProduct = product;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-open');
    this.subs.unsubscribe();
  }
}
