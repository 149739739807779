import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import * as json from '../../../assets/mock/products.json';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-nsbox-title',
  templateUrl: './nsbox-title.component.html',
  styleUrls: ['./nsbox-title.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NsboxTitleComponent implements OnInit {
  public product: any = {};
  public products = [];
  public loadedContent = true;
  public lg;
  public queryParams;
  public animation3 = false;
  public env = environment;
  public imgList = [
    {img: 'cloudy_1w.png'},
    {img: 'nobelic_4m.png'},
    {img: 'nsbox_cam_1.png'},
    {img: 'nsbox-124sl-30_tb.png'},
    {img: 'nsbox-244r62.png'},
    {img: 'nsbox-ice5.png'}
  ];
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const clientHeight = document.documentElement.clientHeight;
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;

    const elem = document.getElementById('product-list');
    if (elem) {
      const top3 = document.getElementById('product-list').getBoundingClientRect().top + scrollTop - clientTop;
      this.animation3 = ((document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop) + clientHeight > top3 ? true : false;
    }
  }
  constructor(private cdr: ChangeDetectorRef,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.products = JSON.parse(JSON.stringify(json.products));
    this.product = this.products.filter(item => item.link === 'nsbox')[0];
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.cdr.detectChanges();
    });
  }
}
