import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Nsb, Products} from '../../domain/main';
import * as json from '../../../assets/mock/products.json';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-nsbon-view',
  templateUrl: './nsbon-view.component.html',
  styleUrls: ['./nsbon-view.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NsbonViewComponent implements OnInit {
  selectedImage = 0;
  public name = '';
  public product = new Nsb();
  products: Products[];
  public env = environment;
  public lg;
  public productParent;
  public queryParams;
  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {
  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = params.id;
      this.products = JSON.parse(JSON.stringify(json.products));
      this.products.forEach(item => {
        const prod = item.catalog.find(el => (el.id === id && el.type === 'NSBon'));
        if (prod) {
          this.product = prod;
          this.productParent = item;
          this.cdr.detectChanges();
        }
      });
    });
    this.route.queryParams.subscribe( queryParams => {
      this.lg = this.env.lang;
      this.queryParams = this.env.queryParams;
      this.cdr.detectChanges();
    });
  }
  public selectImage(i: number) {
    this.selectedImage = i;
  }
  public getBigImage() {
    return this.product.photo.length ? '../../assets/i/nsb/' + this.product.photo[this.selectedImage] : '';
  }
  public getSmallImage(i: number) {
    return this.product.photo.length ? '../../assets/i/nsb-mini/' + this.product.photo[i] : '';
  }
}

